<template>
 <div class="w">
   <div class="crumbs">
     <router-link to="/">首页</router-link>
     <span>/</span>
     {{ route.query.titleOne }}
     <span>/</span>
     {{ route.query.titleTwo }}
     <span>/</span>
     <span style="color: #07C160">正文</span>
   </div>
    <div class="content">
      <Detail :info="info"></Detail>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import Detail from '../components/Detail'
import { getTextDetails } from '../api'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'PolicyDetail',
  components: {
    Detail
  },
  setup () {
    const info = ref({})
    const route = useRoute()

    onMounted(() => {
      getTextDetails(route.query.url, { id: route.query.id, category: route.query.category }).then(res => {
        info.value = {
          ...res.result,
          name: res.result.subject
        }
      })
    })

    return { info,route }
  }
})
</script>

<style lang="scss" scoped>
.content{
  margin-bottom: 70px;
}
</style>
